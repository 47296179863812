import format from 'date-fns/format';
import { v4 as uuid } from 'uuid';
import { INVOICE_TYPES } from '../../../../../config/invoice/constants';
import { getInvoiceTypeTransaction } from '../../../../../helpers/invoiceHelpers';

export const defaultItemData = () => {
  return {
    descriptionAr: '',
    descriptionEn: '',
    description: '',
    unitPrice: '',
    quantity: '',
    discountAmount: '',
    discountPercentage: '',
    vatRateType: '15',
    vatRate: '15%',
    vatAmount: '',
    totalAmount: '',
    creating: true,
  };
};

export const getValueOrString = (obj, key) => obj?.[key] || '';

export const getCreateInvoiceInitialValues = ({
  user,
  invoiceReferenceNumber,
  type,
  language,
  category,
}) =>
  //{ ...defaultStandardForm });
  ({
    invoiceIssueDate: format(new Date(), 'yyyy-MM-dd'),
    invoiceTypeDescription: INVOICE_TYPES[category][language],
    invoiceTypeTransaction: getInvoiceTypeTransaction({
      invoiceType: category,
    }),
    noteType: type === 'creditDebit' ? 'CREDIT' : null,
    invoiceReferenceNumber,
    seller: {
      vatNumber: getValueOrString(user, 'tax_number'),
      groupVatNumber: getValueOrString(user, 'groupVatNumber'),
      sellerEnglishName: getValueOrString(user, 'name'),
      sellerArabicName: getValueOrString(user, 'sellerArabicName'),
      additionalSellerIdType: getValueOrString(user, 'additionalSellerIdType'),
      additionalSellerId: getValueOrString(user, 'additionalSellerId'),
      sellerStreetEn: getValueOrString(user, 'sellerStateEn'),
      sellerStreetAr: getValueOrString(user, 'sellerStateAr'),
      additionalSellerStreetEn: getValueOrString(
        user,
        'additionalSellerStreetEn',
      ),
      additionalSellerStreetAr: getValueOrString(
        user,
        'additionalSellerStreetAr',
      ),
      sellerBuildingNumber: getValueOrString(user, 'sellerBuildingNumber'),
      additionalSellerBuildingNumber: getValueOrString(
        user,
        'additionalSellerBuildingNumber',
      ),
      sellerCity: getValueOrString(user, 'sellerCity'),
      sellerPostalCode: getValueOrString(user, 'sellerPostalCode'),
      sellerStateEn: getValueOrString(user, 'sellerStateEn'),
      sellerStateAr: getValueOrString(user, 'sellerStateAr'),
      sellerNeighborhoodEn: getValueOrString(user, 'sellerNeighborhoodEn'),
      sellerNeighborhoodAr: getValueOrString(user, 'sellerNeighborhoodAr'),
      sellerCountryCode: user?.sellerCountryCode || 'QA',
    },
    buyer: {
      buyerEnglishName: '',
      buyerArabicName: '',
      buyerVatNumber: '',
      buyerGroupVatNumber: '',
      additionalBuyerIdType: '',
      additionalBuyerId: '',
      buyerStreetEn: '',
      buyerStreetAr: '',
      additionalBuyerStreetEn: '',
      additionalBuyerStreetAr: '',
      buyerBuildingNumber: '',
      additionalBuyerBuildingNumber: '',
      buyerCity: '',
      buyerPostalCode: '',
      buyerStateEn: '',
      buyerStateAr: '',
      buyerNeighborhoodEn: '',
      buyerNeighborhoodAr: '',
      buyerCountryCode: 'QA',
    },
    tempItem: {
      id: uuid(),
      ...defaultItemData(),
    },
    items: [],
    notesAr: '',
    notesEn: '',
    supplyStartDate: '',
    supplyEndDate: '',
    purchaseOrderNumber: '',
    contactNumber: '',
    specialTaxTreatmentEn: '',
    specialTaxTreatmentAr: '',
    originalInvoiceReference: '',
    originalGrossAmount: '',
    noteReasonOfIssuance: '',
    paymentTerms: {
      paymentMethod: 'CASH',
      bankNameEn: '',
      bankNameAr: '',
      accountNumber: '',
      paymentTermsAr: '',
      paymentTermsEn: '',
    },
  });
