import React, { useState, useEffect } from 'react';
import TaxReturnDetails from './TaxReturnDetails';
import { useLocation } from 'react-router-dom';
import { STEPS } from './TaxReturnDetails.config';
import { Instructions } from './components/Instructions';
import { TaxpayerDetails } from './components/TaxpayerDetails';
import { ReturnVatFrom } from './components/ReturnVatForm';
import * as api from '../../../services/apis';
import Summary from './components/Summary/Summary';
import {
  prepareTaxReturnApproval,
  parseTaxReturnValuesIntoTwoDecimals,
} from '../../../helpers/taxReturnHelpers';
import moment from 'moment';
import { useKeycloak } from '../../../hooks/useKeycloak';

const TaxReturnDetailsContainer = () => {
  const location = useLocation();

  const steps = STEPS();

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(steps[activeIndex]);
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(false);
  const [successResponse, setSuccessResponse] = useState({});
  const [visible, setVisible] = useState(false);
  const [submitValues, setSubmitValues] = useState({});
  const [loadApprove, setLoadApprove] = useState(false);

  const { userInfo } = useKeycloak();

  const handleContinue = ({ values = null }) => {
    const nextIndex = activeIndex + 1;

    if (nextIndex < steps.length) {
      setActiveIndex(nextIndex);
      setActiveStep(steps[nextIndex]);
      if (values) {
        setSubmitValues(values);
      }
    }
  };

  const getReports = (taxnumber) => {
    const startDate = location?.state?.dateFrom;
    const endDate = location?.state?.dateTo;
    const periodCount = location?.state?.periodCount;
    const referenceNumber = location?.state?.referenceNumber;
    setLoading(true);

    if (startDate && endDate && periodCount) {
      api
        .getDataByPeriod({
          taxnumber,
          startDate,
          endDate,
          periodCount,
          referenceNumber,
        })
        .then((resp) => {
          setReportData(
            parseTaxReturnValuesIntoTwoDecimals({
              ...resp.data,
              taxpayerName: userInfo.name,
              taxnumber,
            }),
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log({ e });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo) {
      getReports(userInfo.tax_number);
    }
  }, [userInfo]);

  const handleSubmit = () => {
    setLoadApprove(true);
    const submitObj = prepareTaxReturnApproval(submitValues, userInfo);
    api
      .approveReturn({
        vatNumber: userInfo.tax_number,
        endDate: moment(submitValues.dateTo).format('DD/MM/YYYY'),
        startDate: moment(submitValues.dateFrom).format('DD/MM/YYYY'),
        periodCount: submitValues.quarterNumber,
        data: submitObj,
      })
      .then((resp) => {
        setLoadApprove(false);
        setSuccessResponse(resp.data);
        setVisible(true);
      })
      .catch(() => {
        setLoadApprove(false);
      });
  };

  const getStepComponent = (value) => {
    switch (value) {
      case 'instructions': {
        return <Instructions onContinue={handleContinue} />;
      }
      case 'taxpayerDetails': {
        return (
          <TaxpayerDetails values={reportData} onContinue={handleContinue} />
        );
      }
      case 'vatReturnForm': {
        return (
          <ReturnVatFrom
            values={reportData}
            setReportData={setReportData}
            onContinue={handleContinue}
          />
        );
      }
      case 'summary': {
        return (
          <Summary
            loadApprove={loadApprove}
            values={submitValues}
            handleSubmit={handleSubmit}
          />
        );
      }
    }
  };

  return (
    <TaxReturnDetails
      activeStep={activeStep}
      activeIndex={activeIndex}
      onContinue={handleContinue}
      getStepComponent={getStepComponent}
      loading={loading}
      successResponse={successResponse}
      visible={visible}
      setVisible={setVisible}
      reportData={reportData}
      approved={location?.state?.approved}
    />
  );
};

export default React.memo(TaxReturnDetailsContainer);
