import React from 'react';
import { OvalSpinner } from '../../../../../../components/shared/OvalSpinner';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const SectionHeader = ({ invoiceCount, invoiceLimit, title, styles }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.sectionHeader}>
      <p className={styles.title}>{t(title)}</p>
      <div className={cn(styles.invoiceCount, 'mb-5')}>
        {t('createInvoice.invoices_generated')}
        {invoiceLimit > 0 ? (
          invoiceCount + ' / ' + invoiceLimit
        ) : (
          <OvalSpinner width={15} height={15} />
        )}
      </div>
    </div>
  );
};

export default React.memo(SectionHeader);
