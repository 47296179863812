import React from 'react';
import { MainLayout } from '../MainLayout';
import { PagesHeader } from '../shared/PagesHeader';
import { TilesWrapper } from '../shared/TilesWrapper';
import { Tile } from '../shared/Tile';
import { PageTile } from '../PageTile';
import { useTranslation } from 'react-i18next';

const Dashboard = ({
  title,
  withBreadcrumb,
  routes,
  pages,
  isEInvoicing,
  tileId,
  isTaxReturn,
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout eInvoicing={isEInvoicing}>
      <PagesHeader
        title={title}
        withBreadcrumb={withBreadcrumb}
        routes={routes}
        isTaxReturn={isTaxReturn}
      />
      <TilesWrapper>
        {pages.map((page, i) => (
          <Tile
            className="col-lg-3 col-md-6 col-sm-12 my-5 bg-white"
            key={i}
            id={tileId + i}
          >
            <PageTile
              redirectUrl={page.redirectUrl}
              iconName={page.iconName}
              title={t(page.title)}
              buttonName={t(page.buttonName)}
              titleClassName={'mx-5'}
              disabled={page.disabled}
            />
          </Tile>
        ))}
      </TilesWrapper>
    </MainLayout>
  );
};

export default React.memo(Dashboard);
