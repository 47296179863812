import React from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import styles from './Modal.styles.scss';
import { Icon } from '../Icon';

const Modal = ({
  withCloseIcon,
  open,
  centered,
  className,
  closeClassName,
  children,
  closeTimeoutMS,
  onClose,
}) => {
  return (
    <ReactModal
      portalClassName={styles.portal}
      overlayClassName={cn(styles.overlay, {
        [styles.overlayCentered]: centered,
      })}
      className={cn(styles.modal, className)}
      isOpen={open}
      closeTimeoutMS={closeTimeoutMS}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      {withCloseIcon && (
        <Icon
          name="cross"
          className={cn(styles.icon, closeClassName)}
          onClick={onClose}
        />
      )}
      {children}
    </ReactModal>
  );
};

Modal.defaultProps = {
  open: false,
  withCloseIcon: true,
  centered: false,
  closeTimeoutMS: 300,
  variant: 'default',
};

export default React.memo(Modal);
