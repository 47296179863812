import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import styles from './SolutionsTable.styles.scss';
import { COLUMNS } from '../../ManageExistingSolutions.config';

const SolutionsTable = ({ solutions, loading, onRevokeClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <DataTable
        value={solutions}
        className="border-rounded my-5"
        // style={{ boxShadow: '0px 0px 10px #00000017', borderRadius: '15px' }}
        loading={loading}
      >
        {COLUMNS({ onRevokeClick, t }).map((column) => (
          <Column
            key={column.label}
            field={column.value}
            header={t(column.label)}
            sortable={column.sortable}
            body={column.body}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default React.memo(SolutionsTable);
