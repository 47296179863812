import React from 'react';
import { Oval } from 'react-loader-spinner';
import { useKeycloak } from '../../../hooks/useKeycloak';
import { THEMES } from '../../../constants/common';

const OvalSpinner = ({ className, color, height, width, ...restProps }) => {
  const { realm } = useKeycloak();
  return (
    <Oval
      height={height}
      width={width}
      color={color || THEMES[realm]?.primary}
      wrapperStyle={{}}
      wrapperClass={className}
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={color}
      strokeWidth={2}
      strokeWidthSecondary={2}
      {...restProps}
    />
  );
};

OvalSpinner.defaultProps = { height: 15, width: 15, color: '#2B3960' };

export default React.memo(OvalSpinner);
