export const ROUTES = [
  {
    label: 'new_registration',
    link: '',
  },
  {
    label: 'solution_registration',
    link: 'solution-registration',
  },
  // {
  //   label: 'breadcrumb_e_invoicing',
  //   link: '',
  // },
];
