export const PAGES = [
  {
    title: 'new_standard_invoice',
    iconName: 'standard',
    buttonName: 'generate',
    redirectUrl: 'standard',
    withChoices: true,
    choices: [
      { label: 'E-Invoice', value: 'standard' },
      { label: 'Debit/Credit Note', value: 'standard-note' },
    ],
    choicesTitle: 'standard_invoice_type',
  },
  {
    title: 'new_simplified_invoice',
    iconName: 'simplified',
    buttonName: 'generate',
    redirectUrl: 'simplified',
    withChoices: true,
    choices: [
      { label: 'E-Invoice', value: 'simplified' },
      { label: 'Debit/Credit Note', value: 'simplified-note' },
    ],
    choicesTitle: 'simplified_invoice_type',
  },
  {
    title: 'view_invoices',
    iconName: 'viewInvoices',
    buttonName: 'view_invoices_btn',
    redirectUrl: 'view-list',
  },
];

export const ROUTES = [
  {
    label: 'sme',
    link: '',
  },
  // {
  //   label: 'breadcrumb_e_invoicing',
  //   link: 'e-invoicing',
  // },
];
