import React from 'react';
import { ViewInput } from '../ViewInput';
import { InvoiceProductSection } from '../InvoiceProductsSection';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const ViewSection = ({ styles, block, blockIdx }) => {
  const { t } = useTranslation();

  return block.sections ? (
    <div className={cn(styles.block, 'row')} key={blockIdx}>
      {block.sections.map((section, sectionIdx) =>
        !section.hide ? (
          <div key={section.key}>
            {section.title && (
              <h4 className={styles.sectionTitle}>{t(section.title)}</h4>
            )}
            <div className={cn('row', styles.section)}>
              {section?.inputs?.map((input, inputIdx) => (
                <ViewInput
                  input={input}
                  index={inputIdx}
                  key={input.label}
                  styles={styles}
                  t={t}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.emptySection} key={section.key} />
        ),
      )}
    </div>
  ) : (
    <div key={blockIdx} className={cn(styles.block, 'row')}>
      <h4 className={styles.sectionTitle}>{t(block.title)}</h4>
      <InvoiceProductSection items={block.items} />
    </div>
  );
};

export default React.memo(ViewSection);
