import React, { useState } from 'react';
import ReturnVatFrom from './ReturnVatFrom';
import { stringToBool } from '../../../../../helpers/common';
import { handleAmountBlur } from './helpers/amountBlurHelper';
import { handleAdjustmentBlur } from './helpers/adjustmentAmountBlurHelper';

const ReturnVatFromContainer = ({ values, setReportData, onContinue }) => {
  const [isReduced, setIsReduced] = useState(false);
  const [isGovernment, setIsGovernment] = useState(false);

  const handleReducedRadio = (e) => {
    setIsReduced(stringToBool(e.target.value));
  };

  const handleGovernmentRadio = (e) => {
    setIsGovernment(stringToBool(e.target.value));
  };

  const handleAdjustment = (e) => {
    setReportData((prev) => ({
      ...prev,
      updatePurchases: {
        ...prev.updatePurchases,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const handelSubmit = (submissionValues) => {
    submissionValues.isReduced = isReduced;
    submissionValues.isGovernment = isGovernment;
    onContinue({ submissionValues });
  };

  return (
    <ReturnVatFrom
      isGovernment={isGovernment}
      isReduced={isReduced}
      handleReducedRadio={handleReducedRadio}
      handleGovernmentRadio={handleGovernmentRadio}
      sales={values?.sales}
      newSales={values?.newSales}
      purchases={values?.purchases}
      updatePurchases={values?.updatePurchases}
      handleAdjustment={handleAdjustment}
      onContinue={onContinue}
      values={values}
      handleAdjustmentBlur={handleAdjustmentBlur}
      handleAmountBlur={handleAmountBlur}
      handleSubmit={handelSubmit}
    />
  );
};

export default React.memo(ReturnVatFromContainer);
