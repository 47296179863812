import React from 'react';
import cn from 'classnames';

const ViewInput = ({ input, index, styles, t }) => {
  return input.value ? (
    <div key={index} className={cn('col-5 my-3', styles.input)}>
      <h5 className={styles.label}>{t(input.label)}</h5>
      <p className={styles.value}>{input.value}</p>
    </div>
  ) : null;
};

export default React.memo(ViewInput);
