import React from 'react';
import styles from './Dashboard.styles.scss';
import { MainLayout } from '../../components/MainLayout';
import { PagesHeader } from '../../components/shared/PagesHeader';
import { ROUTES, VALUES } from './Dashboard.config';
import { Tile } from '../../components/shared/Tile';
import { DoughnutChart } from '../../components/DoughnutChart';
import cn from 'classnames';
import { Button } from '../../components/shared/Button';
import { useKeycloak } from '../../hooks/useKeycloak';
import { TilesWrapper } from '../../components/shared/TilesWrapper';
import { randomKey } from '../../helpers/common';

const Dashboard = ({ onHomeClick, t, values, loading }) => {
  const { realm } = useKeycloak();
  const env = realm;
  return (
    <MainLayout eInvoicing={true}>
      <PagesHeader
        withBreadcrumb={true}
        title={t('dashboard_title')}
        routes={ROUTES}
      />
      <TilesWrapper className={styles['tiles-wrapper']}>
        {VALUES({ t, env, values }).map((item, i) => (
          <Tile
            key={randomKey()}
            className={cn(styles.tile, 'col-lg-3 col-sm-6 col-md-6 m-3 mw-100')}
          >
            <p>{item.title}</p>
            <DoughnutChart
              data={item.values.data}
              colors={item.values.colors}
              legendContainerId={item.type}
              loading={loading}
            />
          </Tile>
        ))}
      </TilesWrapper>
      <div className={styles.btnDiv}>
        <Button
          variant="outlined"
          className={styles.homeBtn}
          onClick={onHomeClick}
          id="dashboard_home_btn"
        >
          {t('common.home')}
        </Button>
      </div>
    </MainLayout>
  );
};

export default React.memo(Dashboard);
