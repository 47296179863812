import React from 'react';
import moment from 'moment';
import { Button } from '../../components/shared/Button';

export const ROUTES = [
  {
    label: 'manage_existing_solution',
    link: '',
  },
  {
    label: 'solution_registration',
    link: 'solution-registration',
  },
  // {
  //   label: 'breadcrumb_e_invoicing',
  //   link: '',
  // },
];

export const COLUMNS = ({ onRevokeClick, t }) => [
  {
    label: 'solutionList.common_name',
    value: 'commonName',
    sortable: false,
  },
  {
    label: 'solutionList.serial_number',
    // value: 'noteType',
    body: (rowData) => rowData?.serialNumber || '-',
    sortable: false,
  },
  {
    label: 'solutionList.vat_number',
    value: 'vatNumber',
    sortable: false,
  },
  {
    label: 'solutionList.organization_name',
    value: 'organizationName',
    sortable: false,
  },
  {
    label: 'solutionList.country_name',
    value: 'countryName',
    sortable: false,
  },
  {
    label: 'solutionList.onboarding_date',
    value: 'onboardingDate',
    body: (rowData) =>
      rowData?.onboardingDate
        ? moment(rowData.onboardingDate, 'YYYY-MM-DD hh:mm:ss').format(
            'DD-MM-YYYY',
          )
        : '-',
    sortable: true,
  },
  {
    label: 'solutionList.certificate_expiry_date',
    value: 'certificateExpiryDate',
    body: (rowData) =>
      rowData?.certificateExpiryDate
        ? moment(rowData.certificateExpiryDate, 'YYYY-MM-DD hh:mm:ss').format(
            'DD-MM-YYYY',
          )
        : '-',
    sortable: true,
  },
  {
    label: 'solutionList.revocation_date',
    value: 'revocationDate',
    body: (rowData) =>
      rowData?.revocationDate
        ? moment(rowData.revocationDate, 'YYYY-MM-DD hh:mm:ss').format(
            'DD-MM-YYYY',
          )
        : '-',
    sortable: true,
  },
  {
    label: 'solutionList.status',
    body: (rowData) => t(`solutionList.${rowData?.status}`),
  },
  {
    label: 'solutionList.revoke',
    body: (rowData) => (
      <Button
        className="px-3"
        style={{ fontSize: '13px', height: '33px', backgroundColor: '#BC0404' }}
        onClick={() => onRevokeClick(rowData.serialNumber)}
        disabled={
          rowData?.status === 'REVOKED' || rowData?.status === 'DEACTIVATED'
        }
      >
        {t('revoke')}
      </Button>
    ),
  },
];
