import * as inputs from './formConfig/inputs';
import * as validations from './formConfig/validations';
import { defaultItemData } from './formConfig/initialValues';

export const ROUTES = (type, t, category) => [
  {
    label: t(type),
    value: '',
  },
  {
    label:
      category === 'standard'
        ? 'generate_standard_title'
        : 'generate_simplified_title',
    value: category,
  },
  {
    label: 'sme',
    link: 'sme',
  },
  // {
  //   label: 'breadcrumb_e_invoicing',
  //   link: 'e-invoicing',
  // },
];

export const STEPS = ({ type, formObject }) => {
  const allSteps = [
    {
      label: 'createInvoice.invoice_type',
      value: 'invoiceType',
      inputs: inputs.invoiceTypeInputs,
      validationSchema: validations.invoiceTypeValidationSchema,
      initialValues: {
        invoiceTypeDescription: formObject.invoiceTypeDescription,
        invoiceTypeTransaction: formObject.invoiceTypeTransaction,
        noteType: formObject.noteType,
      },
      withReset: false,
    },
    {
      label: 'createInvoice.invoice_information',
      value: 'invoiceInformation',
      inputs: inputs.invoiceInformationInputs,
      validationSchema: validations.invoiceInformationValidationSchema,
      initialValues: {
        invoiceIssueDate: formObject.invoiceIssueDate,
        invoiceReferenceNumber: formObject.invoiceReferenceNumber,
        supplyStartDate: formObject.supplyStartDate,
        supplyEndDate: formObject.supplyEndDate,
      },
      withReset: true,
    },
    {
      label: 'createInvoice.seller_identification',
      value: 'sellerIdentification',
      inputs: inputs.sellerIdentificationInputs,
      validationSchema: validations.sellerIdentificationValidationSchema,
      initialValues: {
        sellerEnglishName: formObject.seller.sellerEnglishName,
        vatNumber: formObject.seller.vatNumber,
        groupVatNumber: formObject.seller.groupVatNumber,
        additionalSellerIdType: formObject.seller.additionalSellerIdType,
        additionalSellerId: formObject.seller.additionalSellerId,
      },
      withReset: true,
    },
    {
      label: 'createInvoice.seller_identification_address',
      value: 'sellerAddress',
      inputs: inputs.sellerAddressInputs,
      validationSchema: validations.sellerAddressValidationSchema,
      initialValues: {
        sellerStreetEn: formObject.seller.sellerStreetEn,
        additionalSellerStreetEn: formObject.seller.additionalSellerStreetEn,
        sellerBuildingNumber: formObject.seller.sellerBuildingNumber,
        additionalSellerBuildingNumber:
          formObject.seller.additionalSellerBuildingNumber,
        sellerCity: formObject.seller.sellerCity,
        sellerPostalCode: formObject.seller.sellerPostalCode,
        sellerStateEn: formObject.seller.sellerStateEn,
        sellerNeighborhoodEn: formObject.seller.sellerNeighborhoodEn,
        sellerCountryCode: formObject.seller.sellerCountryCode,
      },
      withReset: true,
    },
    {
      label: 'createInvoice.buyer_identification',
      value: 'buyerIdentification',
      inputs: inputs.buyerIdentificationInputs,
      validationSchema: validations.buyerIdentificationValidationSchema,
      initialValues: {
        buyerEnglishName: formObject.buyer.buyerEnglishName,
        buyerVatNumber: formObject.buyer.buyerVatNumber,
        buyerGroupVatNumber: formObject.buyer.buyerGroupVatNumber,
        additionalBuyerIdType: formObject.buyer.additionalBuyerIdType,
        additionalBuyerId: formObject.buyer.additionalBuyerId,
      },
      withReset: true,
    },
    {
      label: 'createInvoice.buyer_identification_address',
      value: 'buyerAddress',
      inputs: inputs.buyerAddressInputs,
      validationSchema: validations.buyerAddressValidationSchema,
      initialValues: {
        buyerStreetEn: formObject.buyer.buyerStreetEn,
        additionalBuyerStreetEn: formObject.buyer.additionalBuyerStreetEn,
        buyerBuildingNumber: formObject.buyer.buyerBuildingNumber,
        additionalBuyerBuildingNumber:
          formObject.buyer.additionalBuyerBuildingNumber,
        buyerCity: formObject.buyer.buyerCity,
        buyerPostalCode: formObject.buyer.buyerPostalCode,
        buyerStateEn: formObject.buyer.buyerStateEn,
        buyerNeighborhoodEn: formObject.buyer.buyerNeighborhoodEn,
        buyerCountryCode: formObject.buyer.buyerCountryCode,
      },
      withReset: true,
    },
    {
      label: 'createInvoice.order_reference',
      value: 'orderReference',
      inputs: inputs.orderReferenceInputs,
      validationSchema: validations.orderReferenceValidationSchema,
      initialValues: {
        purchaseOrderNumber: formObject.purchaseOrderNumber,
        contactNumber: formObject.contactNumber,
        withReset: true,
      },
      withReset: true,
    },
    {
      label: 'createInvoice.line_items',
      value: 'lineItems',
      inputs: inputs.lineItemsInputs,
      validationSchema: validations.lineItemsValidationSchema,
      initialValues: {
        items: [],
        tempItem: { ...defaultItemData() },
      },
      withReset: true,
    },
    {
      label: 'createInvoice.payment_terms',
      value: 'paymentTerms',
      inputs: inputs.paymentTermsInputs,
      validationSchema: validations.paymentTermsValidationSchema,
      initialValues: {
        paymentMethod: formObject.paymentMethod,
        bankNameEn: formObject.bankNameEn,
        accountNumber: formObject.accountNumber,
        paymentTermsEn: formObject.paymentTermsEn,
      },
      withReset: true,
    },
    {
      label: 'createInvoice.reference',
      value: 'reference',
      inputs: inputs.referenceInputs,
      validationSchema: validations.referenceValidationsSchema,
      initialValues: {
        originalInvoiceReference: formObject.originalInvoiceReference,
        originalGrossAmount: formObject.originalGrossAmount,
        noteReasonOfIssuance: formObject.noteReasonOfIssuance,
      },
      withReset: true,
    },
    {
      label: 'createInvoice.notes',
      value: 'notes',
      inputs: inputs.notesInputs,
      validationSchema: validations.notesValidationSchema,
      initialValues: {
        notesEn: formObject.notesEn,
        specialTaxTreatmentEn: formObject.specialTaxTreatmentEn,
      },
      withReset: true,
    },
  ];
  if (type === 'e_invoice') {
    allSteps.splice(9, 1);
  }
  return allSteps;
};
