import React from 'react';
import { Icon } from '../Icon';
import styles from './BackButton.styles.scss';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const BackButton = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <span onClick={() => navigate(-1)} className={styles.backSpan}>
      <Icon name="leftArrow" className={cn(styles.backArrow, 'mx-2')} />{' '}
      <p>{t('common.back')}</p>
    </span>
  );
};

export default React.memo(BackButton);
