import { PERIODS } from '../pages/TaxReturn/TaxReturn.config';
import moment from 'moment';

export const getTaxPeriod = ({ periodType, count, dateFrom }) => {
  const periodYear = moment(dateFrom, 'dd/mm/yyyy').year();
  const periodInstance = PERIODS[periodType];
  return `${periodInstance} ${count} - ${periodYear}`;
};

const newSalesInitialValues = {
  standardAmount: 0.0,
  standardAdj: 0.0,
  standardVat: 0.0,
  standardReason: null,
  reducedAmount: 0.0,
  reducedAdj: 0.0,
  reducedVat: 0.0,
  reducedReason: null,
  stateTaxableSales: 0.0,
  stateTaxableSalesAdj: 0.0,
  stateTaxableSalesReason: null,
  zeroRated: 0.0,
  zeroRatedAdj: 0.0,
  zeroRatedReason: null,
  exports: 0.0,
  exportsAdj: 0.0,
  exportsReason: null,
  exempt: 0.0,
  exemptAdj: 0.0,
  exemptReason: null,
  totalSales: 0.0,
  totalSalesAdj: 0.0,
  totalSalesVat: 0.0,
};

const newPurchasesInitialValues = {
  standardRatedPurchase: 0.0,
  standardRatedAdj: 0.0,
  standardRateTaxAmount: 0.0,
  standardRateReason: null,
  reducedRate: 0.0,
  reducedRateAdj: 0.0,
  reducedRateTaxAmount: 0.0,
  reducedRateReason: null,
  importsSubjectToVat: 0.0,
  importsSubjectToVatAdj: 0.0,
  importsSubjectToVatTaxAmount: 0.0,
  importsReason: null,
  reducedImportsSubjectToVat: 0.0,
  reducedImportsSubjectToVatAdj: 0.0,
  reducedImportsSubjectToVatTaxAmount: 0.0,
  reducedImportsReason: null,
  reverseImports: 0.0,
  reverseImportsAdj: 0.0,
  reverseImportsTaxAmount: 0.0,
  reverseImportsReason: null,
  reducedReverseImports: 0.0,
  reducedReverseImportsAdj: 0.0,
  reducedReverseImportsTaxAmount: 0.0,
  reducedReverseImportsReason: null,
  zeroRated: 0.0,
  zeroRatedAdj: 0.0,
  zeroReason: null,
  exempt: 0.0,
  exemptAdj: 0.0,
  exemptReason: null,
  totalPurchases: 0.0,
  totalPurchasesAdj: 0.0,
  totalPurchasesTaxAmount: 0.0,
};

export const prepareTaxReturnApproval = (values, user) => {
  return {
    dateFrom: moment(values.dateFrom).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    dateTo: moment(values.dateTo).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    reportCount: values.reportCount,
    returnType: values.returnType,
    vatNumber: user.tax_number,
    purchases: {
      ...values?.purchases,
    },
    sales: {
      ...values?.sales,
    },
    updatePurchases: {
      ...newPurchasesInitialValues,
      ...values?.newPurchases,
    },
    newSales: {
      ...newSalesInitialValues,
      ...values?.newSales,
    },
    approved: true,
    name: user.name,
    currentTotalVatDue: values?.currentTotalVatDue,
    correctionFromPrevious: values?.correctionFromPrevious,
    correctionFromPreviousValue: values?.correctionFromPreviousValue || 0,
    correctionFromPreviousAdj: values?.correctionFromPreviousAdj || 0,
    vatCreditCarriedForward: values?.vatCreditCarriedForward,
    netVatDue: values?.netVatDue,
    reduced: values?.isReduced,
    government: values?.isGovernment,
  };
};

export const getRegistersVatRateType = (vatRateType, reason, vatRate) => {
  let type = '';

  switch (vatRateType) {
    case 'Z': {
      type = reason === 'VATEX_SA_34_5' ? 'Z-E' : 'Z-D';
      break;
    }
    case 'S': {
      type = +vatRate === 5 ? 'S-5%' : 'S-15%';
      break;
    }
    default: {
      type = vatRateType;
      break;
    }
  }

  return type;
};

const convertAllToFixedTwo = (obj) => {
  if (obj) {
    const newObj = { ...obj };
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'number') {
        newObj[key] = Number(obj[key]).toFixed(2) || 0.0;
      }
    });
    return newObj;
  } else {
    return null;
  }
};

export const parseTaxReturnValuesIntoTwoDecimals = (obj) => {
  return {
    ...obj,
    correctionFromPrevious:
      Number(obj.correctionFromPrevious).toFixed(2) || 0.0,
    correctionFromPreviousAdj:
      Number(obj.correctionFromPreviousAdj).toFixed(2) || 0.0,
    correctionFromPreviousValue:
      Number(obj.correctionFromPreviousValue).toFixed(2) || 0.0,
    currentTotalVatDue: Number(obj.currentTotalVatDue).toFixed(2) || 0.0,
    netVatDue: Number(obj.netVatDue).toFixed(2) || 0.0,
    vatCreditCarriedForward:
      Number(obj.vatCreditCarriedForward.toFixed(2)) || 0.0,
    purchases: {
      ...obj.purchases,
      ...convertAllToFixedTwo(obj.purchases),
    },
    sales: {
      ...obj.sales,
      ...convertAllToFixedTwo(obj.sales),
    },
    newPurchases: {
      ...obj?.newPurchases,
      ...convertAllToFixedTwo(obj.newPurchases),
    },
    newSales: {
      ...obj?.newSales,
      ...convertAllToFixedTwo(obj.newSales),
    },
  };
};
