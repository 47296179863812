import React, { useState, useEffect } from 'react';
import SalesRegister from './SalesRegister';
import * as api from '../../../../services/apis';
import { useKeycloak } from '../../../../hooks/useKeycloak';

const SalesRegisterContainer = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  const { userInfo } = useKeycloak();

  const getRegisters = ({
    taxNumber,
    dateFrom = '',
    dateTo = '',
    invoiceType = '',
    searchQuery = '',
  }) => {
    setLoading(true);
    api
      .getSalesRegister({
        taxNumber,
        dateFrom,
        dateTo,
        invoiceType,
        searchQuery,
      })
      .then((resp) => {
        setInvoices(resp.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log({ e });
        setInvoices([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userInfo) {
      getRegisters({ taxNumber: userInfo.tax_number });
    }
  }, [userInfo]);

  return (
    <SalesRegister
      setInvoices={setInvoices}
      invoices={invoices}
      getRegisters={getRegisters}
      loading={loading}
    />
  );
};

export default React.memo(SalesRegisterContainer);
