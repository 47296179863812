import React from 'react';
import SideBarMenu from './SideBarMenu';
import { CNavItem, CNavLink } from '@coreui/react';
import styles from './SideBarMenu.styles.scss';
import cn from 'classnames';
import { LanguageSelect } from '../LanguageSelect';
import { Icon } from '../../../shared/Icon';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '../../../../hooks/useKeycloak';

const SideBarMenuContainer = () => {
  const { i18n } = useTranslation();
  const { logout } = useKeycloak();

  const userLogout = () => {
    logout();
  };

  return (
    <SideBarMenu>
      <CNavItem>
        <CNavLink className={cn(styles.navLink, 'w-100')} href="#" active>
          {i18n.t('common.home')}
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <LanguageSelect
          className={styles.changeLanguage}
          iconName="arrowDownDarkBlue"
        />
      </CNavItem>
      <CNavItem className="mt-4">
        <div className={styles.logoutBtn} id="logout_btn">
          <Icon onClick={userLogout} name="logoutLogoSidebar" />
        </div>
      </CNavItem>
    </SideBarMenu>
  );
};

export default React.memo(SideBarMenuContainer);
