export const LINE_ITEMS_COLUMNS = [
  {
    key: 'description',
    label: 'createInvoice.product_or_service_description',
    _props: { scope: 'col' },
  },
  {
    key: 'unitPrice',
    label: 'createInvoice.unit_price',
    _props: { scope: 'col' },
  },
  {
    key: 'quantity',
    label: 'createInvoice.quantity',
    _props: { scope: 'col' },
  },
  {
    key: 'discountPercentage',
    label: 'createInvoice.discount_or_rebate_percentage',
    _props: { scope: 'col' },
  },
  {
    key: 'discountAmount',
    label: 'createInvoice.discount_or_rebate_amount',
    _props: { scope: 'col' },
  },
  {
    key: 'vatRate',
    label: 'createInvoice.vat_rate',
    _props: { scope: 'col' },
  },
  {
    key: 'vatAmount',
    label: 'createInvoice.vat_amount',
    _props: { scope: 'col' },
  },
  {
    key: 'totalAmount',
    label: 'createInvoice.total',
    _props: { scope: 'col' },
  },
];
