import { SELECT_AN_OPTION_LABEL } from '../../constants/common';

export const PAYMENT_METHOD = (lang) =>
  lang === 'en'
    ? [
        { label: SELECT_AN_OPTION_LABEL, value: null },
        { label: 'Cash', value: 'CASH' },
        { label: 'Bank Transfer', value: 'BANK_TRANSFER' },
      ]
    : [
        { label: 'اختر', value: null },
        { label: 'نقداً', value: 'CASH' },
        { label: 'حوالة بنكية', value: 'BANK_TRANSFER' },
      ];

export const NOTE_TYPES = (lang) =>
  lang === 'en'
    ? [
        { label: SELECT_AN_OPTION_LABEL, value: '' },
        { label: 'Credit Note', value: 'CREDIT' },
        { label: 'Debit Note', value: 'DEBIT' },
      ]
    : [
        { label: 'اختر', value: '' },
        { label: 'إشعار دائن', value: 'CREDIT' },
        { label: 'إشعار مدين', value: 'DEBIT' },
      ];

export const VAT_TYPES = (lang) =>
  lang === 'en'
    ? [
        { label: SELECT_AN_OPTION_LABEL, value: '' },
        { label: 'Standard Rate', value: 'S' },
        { label: 'Zero Rate', value: 'Z' },
      ]
    : [
        { label: 'اختر', value: '' },
        { label: 'الضريبة العادية', value: 'S' },
        { label: 'الضريبة الصفرية', value: 'Z' },
      ];

export const ADDITIONAL_SELLER_ID_TYPES = (lang) =>
  lang === 'en'
    ? [
        { label: SELECT_AN_OPTION_LABEL, value: '' },
        { label: 'Commercial Registration (CR)', value: 'CR' },
        { label: 'Other ID', value: 'OTHER' },
      ]
    : [
        { label: 'اختر', value: '' },
        { label: 'السجل التجاري', value: 'CR' },
        { label: 'غيرها', value: 'OTHER' },
      ];

export const CITIES = (lang) =>
  lang === 'en'
    ? [
        { label: SELECT_AN_OPTION_LABEL, value: '' },
        { label: 'Doha', value: 'DOHA' },
        { label: 'Al Rayyan', value: 'AL_RAYYAN' },
        { label: 'Al Wakrah', value: 'AL_WAKRAH' },
        { label: 'Al Khor', value: 'AL_KHOR' },
        { label: 'Dukhan', value: 'DUKHAN' },
        { label: 'Al Shamal', value: 'AL_SHAMAL' },
        { label: 'Mesaieed', value: "MESAI'EED" },
        { label: 'Umm Salal', value: 'UMM_SALAL' },
      ]
    : [
        { label: 'اختر', value: '' },
        { label: 'الدوحة', value: 'DOHA' },
        { label: 'الريان', value: 'AL_RAYYAN' },
        { label: 'الوكرة', value: 'AL_WAKRAH' },
        { label: 'الخور', value: 'AL_KHOR' },
        { label: 'دخان', value: 'DUKHAN' },
        { label: 'الشمال', value: 'AL_SHAMAL' },
        { label: 'مسيعيد', value: "MESAI'EED" },
        { label: 'أم صلال', value: 'UMM_SALAL' },
      ];

export const ADDITIONAL_BUYER_ID_TYPES = (lang) =>
  lang === 'en'
    ? [
        { label: SELECT_AN_OPTION_LABEL, value: '' },
        { label: 'National ID', value: 'NATIONAL_ID_NUMBER' },
        { label: 'Personal Number', value: 'PERSONAL_NUMBER' },
      ]
    : [
        { label: 'اختر', value: '' },
        { label: 'رقم الهوية', value: 'NATIONAL_ID_NUMBER' },
        { label: 'الرقم الشخصي', value: 'PERSONAL_NUMBER' },
      ];

export const INVOICE_TYPES = (lang) =>
  lang === 'ar'
    ? [
        { label: 'اختر', value: '' },
        { label: 'فاتورة ضريبية', value: 'STANDARD_TAX' },
        { label: 'فاتورة مبسطة', value: 'SIMPLIFIED_TAX' },
      ]
    : [
        { label: SELECT_AN_OPTION_LABEL, value: '' },
        { label: 'Standard Tax', value: 'STANDARD_TAX' },
        { label: 'Simplified Tax', value: 'SIMPLIFIED_TAX' },
      ];

export const INVOICE_STATUS = (lang) =>
  lang === 'ar'
    ? [
        { label: 'اختر', value: '' },
        { label: 'مرسلة', value: 'SUBMITTED' },
        { label: 'مرفوضة', value: 'REJECTED' },
        // { label: 'مسودة', value: 'DRAFT' },
      ]
    : [
        { label: SELECT_AN_OPTION_LABEL, value: '' },
        { label: 'Submitted', value: 'SUBMITTED' },
        { label: 'Rejected', value: 'REJECTED' },
        // { label: 'Draft', value: 'DRAFT' },
      ];

export const NOTE_ISSUANCE_REASONS = (lang) =>
  lang === 'ar'
    ? [
        { label: 'اختر', value: '' },
        {
          label:
            'تم إلغاء أو وقف التوريد بعد حدوثه أو إعتباره حدث كلياً أو جزئي',
          value: 'CANCELLATION_OR_TERMINATION',
        },
        {
          label:
            'وجود تغيير أو تعديل جوهري في طبيعة التوريد بحيث يؤدي إلى تغيير الضريبة المستحقة',
          value: 'MATERIAL_CHANGE_TO_NATURE_OF_SUPPLY',
        },
        {
          label:
            'عند ترجيع السلع أو الخدمات أو أي جزء منها إلى المورد ويقوم المورد بقبول استرجاعها',
          value: 'RETURN_OF_GOODS',
        },
        {
          label:
            'تم اكتشاف خطأ في تفاصيل المورد أو المشتري المضمنة في الفاتورة الأصلية',
          value: 'INCORRECT_INFORMATION',
        },
      ]
    : [
        { label: SELECT_AN_OPTION_LABEL, value: '' },
        {
          label:
            'The Supply is cancelled or terminated after the Supply has taken place or been \n treated as taking place',
          value: 'CANCELLATION_OR_TERMINATION',
        },
        {
          label:
            'There is a material change or alteration to the nature of the Supply resulting\n in a change in the Tax charged',
          value: 'MATERIAL_CHANGE_TO_NATURE_OF_SUPPLY',
        },
        {
          label:
            'The Goods or services or part thereof are returned to the Supplier and the\n Supplier accepts such return',
          value: 'RETURN_OF_GOODS',
        },
        {
          label: "In case of change in Seller's or Buyer's information",
          value: 'INCORRECT_INFORMATION',
        },
      ];

export const PREVIOUS_INVOICES = ({ previousInvoices, language }) => {
  return language === 'en'
    ? [
        { label: SELECT_AN_OPTION_LABEL, value: '' },
        ...previousInvoices.map((invoice) => ({
          value: invoice.irn,
          label: invoice.irn,
        })),
      ]
    : [
        { label: 'اختر', value: '' },
        ...previousInvoices.map((invoice) => ({
          value: invoice.irn,
          label: invoice.irn,
        })),
      ];
};
