import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../shared/Icon';
import styles from './FooterSection.styles.scss';
import { CForm, CFormInput } from '@coreui/react';
import { Button } from '../../../shared/Button';
import cn from 'classnames';
import { randomKey } from '../../../../helpers/common';

const FooterSection = ({ data }) => {
  const { i18n, t } = useTranslation();
  return (
    <div className="col-lg-3 col-md-6 col-sm-12">
      <p className={cn(styles.title, 'mb-3')}>{data.title[i18n.language]}</p>
      {data.type !== 'social' ? (
        <ul className={styles.links}>
          {data.links?.map((link, i) => (
            <li key={randomKey()} className="mb-2">
              <a href={link.url}>{link.label[i18n.language]}</a>
            </li>
          ))}
        </ul>
      ) : (
        <ul className={styles.links}>
          {data.links?.map((link, i) => (
            <li key={randomKey()} className="mb-3">
              <Icon name={link.icon} />
              <a href={link.url} className="mx-2">
                {link.label[i18n.language]}
              </a>
            </li>
          ))}
          <li>
            <CForm className="d-flex">
              <CFormInput
                // type="Write your Email"
                className={styles.search}
                placeholder={t('write_your_email')}
              />
              <Button
                id="subscribe_btn"
                type="submit"
                className={styles.subscribeBtn}
              >
                {t('subscribe')}
              </Button>
            </CForm>
          </li>
        </ul>
      )}
    </div>
  );
};

export default React.memo(FooterSection);
