import moment from 'moment';

export const ROUTES = [
  {
    label: 'view_invoices',
    link: '',
  },
  {
    label: 'sme',
    link: 'sme',
  },
  // {
  //   label: 'breadcrumb_e_invoicing',
  //   link: 'e-invoicing',
  // },
];

export const COLUMNS = [
  {
    label: '',
    value: 'number',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_type_discription',
    value: 'invoiceType',
    sortable: false,
  },
  {
    label: 'invoice_list.type_of_note',
    // value: 'noteType',
    body: (rowData) => rowData.noteType || '-',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_reference_number',
    value: 'invoiceReferenceNumber',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_issue_date',
    value: 'issueDate',
    body: (rowData) =>
      rowData.issueDate
        ? moment(rowData.issueDate, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')
        : '-',
    sortable: false,
  },
  {
    label: 'invoice_list.customer_name',
    value: 'englishBuyerName',
    sortable: false,
  },
  {
    label: 'invoice_list.invoice_gross_total',
    value: 'grossTotalIncludingVat',
    sortable: false,
  },
];
