import Keycloak from 'keycloak-js';

const keycloak = ({ KEYCLOAK_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID }) => {
  const kc = new Keycloak({
    realm: KEYCLOAK_REALM,
    url: KEYCLOAK_URL,
    clientId: KEYCLOAK_CLIENT_ID,
  });
  return kc;
};

export default keycloak;
