import React from 'react';
import styles from './TotalsTable.styles.scss';
import cn from 'classnames';
import {
  calculateTotalExcludingTax,
  calculateTotalIncludingTax,
  calculateTotalDiscountAmount,
  calculateTotalVatAmount,
} from '../../../../../../helpers/invoiceHelpers';
import { useTranslation } from 'react-i18next';

const TotalsTable = ({ items }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('mt-5', styles.container)}>
      <div className={styles.inner}>
        <div className={cn(styles.headers)}>
          <p>{t('createInvoice.invoice_total_amount_excluding_vat')}</p>
        </div>
        <div className={styles.data}>
          {Number(calculateTotalExcludingTax(items)).toFixed(3)}
        </div>
      </div>
      <div className={styles.inner}>
        <div className={styles.headers}>
          <p>{t('createInvoice.discount_or_rebate_total_amount')}</p>
        </div>
        <div className={styles.data}>
          {Number(calculateTotalDiscountAmount(items)).toFixed(3)}
        </div>
      </div>
      <div className={styles.inner}>
        <div className={styles.headers}>
          <p>{t('createInvoice.vat_total_amount')}</p>
        </div>
        <div className={styles.data}>
          {Number(calculateTotalVatAmount(items)).toFixed(3)}
        </div>
      </div>
      <div className={styles.inner}>
        <div className={styles.headers}>
          <p>{t('createInvoice.invoice_gross_total_inclusive_vat')}</p>
        </div>
        <div className={styles.data}>
          {Number(calculateTotalIncludingTax(items)).toFixed(3)}
        </div>
      </div>
      <div className={styles.inner}>
        <div className={cn(styles.headers, styles.lastHeader)}>
          <p>{t('createInvoice.invoice_total_payable_amount')}</p>
        </div>
        <div className={styles.data}>
          {Number(calculateTotalIncludingTax(items)).toFixed(3)}
        </div>
      </div>
    </div>
  );
};

export default React.memo(TotalsTable);
