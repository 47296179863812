import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LINE_ITEMS_COLUMNS } from './LineItemsTable.config';
import styles from './LineItemsTable.styles.scss';
import { useTranslation } from 'react-i18next';

const LineItemsTable = ({ items }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <DataTable
        scrollable
        scrollHeight="400px"
        value={items}
        tableStyle={{ minWidth: '50rem' }}
        className="line-items-datatable"
      >
        {LINE_ITEMS_COLUMNS.map((col, i) => (
          <Column
            key={col.key}
            field={col.key}
            header={t(col.label)}
            minWidth={'50rem'}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default React.memo(LineItemsTable);
