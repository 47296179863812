import React, { useState, useEffect } from 'react';
import InvoiceList from './InvoiceList';
import { addIds } from './helpers/invoiceListHelpers';
import * as api from '../../../../services/apis';
import { useKeycloak } from '../../../../hooks/useKeycloak';

const InvoiceListContainer = () => {
  const [originalInvoices, setOriginalInvoices] = useState([]);
  const [renderInvoices, setRenderInvoices] = useState(
    Array.from({ length: 4 }),
  );
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState({
    thisYear: 0,
    limit: 0,
    invoicesAllTime: 0,
    notesAllTime: 0,
    total: 0,
  });

  const { userInfo } = useKeycloak();

  const getInvoicesList = (tax_number) => {
    api
      .getInvoicesList(tax_number)
      .then((resp) => {
        const invoices = resp?.data?.invoiceList;
        const generatedThisYear = resp?.data?.generatedThisYear;
        const generatedInvoices = resp?.data?.generatedInvoices;
        const generatedNotes = resp?.data?.generatedNotes;
        setStatistics({
          thisYear: generatedThisYear,
          limit: 100,
          invoicesAllTime: +generatedInvoices,
          notesAllTime: +generatedNotes,
          total: +generatedInvoices + +generatedNotes,
        });
        setOriginalInvoices(invoices);
        setRenderInvoices(invoices);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  useEffect(() => {
    if (userInfo) {
      getInvoicesList(userInfo.tax_number);
    }
  }, [userInfo]);

  const invoicesWithIds = addIds(renderInvoices);

  return (
    <InvoiceList
      statistics={statistics}
      invoices={invoicesWithIds}
      setRenderInvoices={setRenderInvoices}
      originalInvoices={originalInvoices}
      loading={loading}
    />
  );
};

export default React.memo(InvoiceListContainer);
