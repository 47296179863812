import { INVOICE_TYPE_LABELS } from '../../../../config/invoice/constants';

import {
  ADDITIONAL_SELLER_ID_TYPES,
  ADDITIONAL_BUYER_ID_TYPES,
  CITIES,
  PAYMENT_METHOD,
  NOTE_ISSUANCE_REASONS,
} from '../../../../config/invoice/options';
import { getQrCodeImage } from '../../../../helpers/invoiceHelpers';

export const ROUTES = (type) => {
  return [
    {
      label: 'view_invoice',
      link: '',
    },
    {
      label: 'sme',
      link: 'sme',
    },
    // {
    //   label: 'breadcrumb_e_invoicing',
    //   link: 'e-invoicing',
    // },
  ];
};

export const getTitles = (type) => {
  switch (type) {
    case 'STANDARD_TAX':
      return {
        en: 'Standard E-Invoice',
        ar: 'فاتورة الكترونية',
      };
    case 'STANDARD_CREDIT_NOTE':
      return {
        en: 'Standard Credit/Debit Note',
        ar: 'إشعار إلكتروني دائن أو مدين',
      };
    case 'STANDARD_DEBIT_NOTE':
      return {
        en: 'Standard Credit/Debit Note',
        ar: 'إشعار إلكتروني دائن أو مدين',
      };
    case 'SIMPLIFIED_TAX':
      return {
        en: 'Simplified E-Invoice',
        ar: 'فاتورة الكترونية مبسطة',
      };
    case 'SIMPLIFIED_CREDIT_NOTE':
      return {
        en: 'Simplified Credit/Debit Note',
        ar: 'إشعار إلكتروني مبسط دائن أو مدين',
      };
    case 'SIMPLIFIED_DEBIT_NOTE':
      return {
        en: 'Simplified Credit/Debit Note',
        ar: 'إشعار إلكتروني مبسط دائن أو مدين',
      };
  }
};

export const viewObject = ({ obj, language }) => {
  const blocks = [
    {
      key: 'invoiceInfoBlock',
      sections: [
        {
          key: 'invoiceInfo',
          title: '',
          inputs: [
            {
              label: 'createInvoice.invoice_reference_number',
              value: obj.invoiceNumber,
            },
            {
              label: 'createInvoice.invoice_issue_date',
              value: obj.issueDate,
            },
            {
              label: 'createInvoice.invoice_type_description',
              value:
                (obj.invoiceTypeCode &&
                  INVOICE_TYPE_LABELS(language)[obj.invoiceTypeCode]) ||
                '',
            },
            {
              label: 'createInvoice.invoice_type_transaction',
              value: obj.invoiceTypeTransaction,
            },
            {
              label: 'createInvoice.supply_start_date',
              value: obj.supplyStartDate,
            },
            {
              label: 'createInvoice.supply_end_date',
              value: obj.supplyEndDate,
            },
            {
              label: 'createInvoice.purchase_order_number',
              value: obj.purchaseOrderNumber,
            },
            {
              label: 'createInvoice.contract_number',
              value: obj.contactNumber,
            },
          ],
        },
      ],
      image: getQrCodeImage(obj.qrCode),
    },
    {
      key: 'sellerBlock',
      sections: [
        {
          key: 'identification',
          title: 'createInvoice.seller_identification',
          inputs: [
            {
              label: 'createInvoice.seller_name',
              value: obj.sellerDTO.sellerEnglishName,
            },
            {
              label: 'createInvoice.vat_registration_number',
              value: obj.sellerDTO.vatNumber,
            },
            {
              label: 'createInvoice.group_vat_registration_number',
              value: obj.sellerDTO.groupVatNumber,
            },
            {
              label: 'createInvoice.additional_seller_id_type',
              value:
                (obj?.sellerDTO?.additionalSellerIdType &&
                  ADDITIONAL_SELLER_ID_TYPES(language)?.find(
                    (ele) => ele.value === obj.sellerDTO.additionalSellerIdType,
                  )?.label) ||
                '',
            },
            {
              label: 'createInvoice.additional_seller_id_number',
              value: obj.sellerDTO.additionalSellerId,
            },
          ],
        },
        {
          key: 'address',
          title: 'createInvoice.seller_identification_address',
          inputs: [
            {
              label: 'createInvoice.street_name',
              value: obj.sellerDTO.sellerStreetEn,
            },
            {
              label: 'createInvoice.additional_street',
              value: obj.sellerDTO.additionalSellerStreetEn,
            },
            {
              label: 'createInvoice.building_number',
              value: obj.sellerDTO.sellerBuildingNumber,
            },
            {
              label: 'createInvoice.additional_number',
              value: obj.sellerDTO.additionalSellerBuildingNumber,
            },
            {
              label: 'createInvoice.city',
              value:
                (obj?.sellerDTO?.sellerCity &&
                  CITIES(language).find(
                    (ele) => ele.value === obj.sellerDTO.sellerCity,
                  )?.label) ||
                '',
            },
            {
              label: 'createInvoice.postal_code',
              value: obj.sellerDTO.sellerPostalCode,
            },
            {
              label: 'createInvoice.province_state',
              value: obj.sellerDTO.sellerStateEn,
            },
            {
              label: 'createInvoice.neighborhood',
              value: obj.sellerDTO.sellerNeighborhoodEn,
            },
            {
              label: 'createInvoice.country_code',
              value: obj.sellerDTO.sellerCountryCode,
            },
          ],
        },
      ],
      image: null,
    },
    {
      key: 'buyerBlock',
      sections: [
        {
          key: 'identification',
          title: 'createInvoice.buyer_identification',
          inputs: [
            {
              label: 'createInvoice.buyer_name',
              value: obj.buyerDTO.buyerEnglishName,
            },
            {
              label: 'createInvoice.vat_registration_number',
              value: obj.buyerDTO.buyerVatNumber,
            },
            {
              label: 'createInvoice.group_vat_registration_number',
              value: obj.buyerDTO.buyerGroupVatNumber,
            },
            {
              label: 'createInvoice.additional_buyer_id_type',
              value:
                (obj.buyerDTO.additionalBuyerIdType &&
                  ADDITIONAL_BUYER_ID_TYPES(language)?.find(
                    (ele) => ele.value === obj.buyerDTO.additionalBuyerIdType,
                  )?.label) ||
                '',
            },
            {
              label: 'createInvoice.additional_buyer_id_number',
              value: obj.buyerDTO.additionalBuyerId,
            },
          ],
        },
        {
          key: 'address',
          title: 'createInvoice.buyer_identification_address',
          inputs: [
            {
              label: 'createInvoice.street_name',
              value: obj.buyerDTO.buyerStreetEn,
            },
            {
              label: 'createInvoice.additional_street',
              value: obj.buyerDTO.additionalBuyerStreetEn,
            },
            {
              label: 'createInvoice.building_number',
              value: obj.buyerDTO.buyerBuildingNumber,
            },
            {
              label: 'createInvoice.additional_number',
              value: obj.buyerDTO.additionalBuyerBuildingNumber,
            },
            {
              label: 'createInvoice.city',
              value:
                (obj.buyerDTO.buyerCity &&
                  CITIES(language).find(
                    (ele) => ele.value === obj.buyerDTO.buyerCity,
                  )?.label) ||
                '',
            },
            {
              label: 'createInvoice.postal_code',
              value: obj.buyerDTO.buyerPostalCode,
            },
            {
              label: 'createInvoice.province_state',
              value: obj.buyerDTO.buyerStateEn,
            },
            {
              label: 'createInvoice.neighborhood',
              value: obj.buyerDTO.buyerNeighborhoodEn,
            },
            {
              label: 'createInvoice.country_code',
              value: obj.buyerDTO.buyerCountryCode,
            },
          ],
        },
      ],
      image: null,
    },
    {
      key: 'lineItemsBlock',
      title: 'createInvoice.line_items',
      items: [...obj.invoiceItems],
    },
    {
      key: 'paymentTermsBlock',
      sections: [
        {
          key: 'terms',
          title: 'createInvoice.payment_terms',
          inputs: [
            {
              label: 'createInvoice.payment_method',
              value:
                (obj.paymentTermsDTO?.paymentMethodCode &&
                  PAYMENT_METHOD(language)?.find(
                    (method) =>
                      method.value === obj.paymentTermsDTO?.paymentMethodCode,
                  )?.label) ||
                '',
            },
            {
              label: 'createInvoice.bank_name',
              value: obj.paymentTermsDTO?.bankNameEn,
              hide: obj.paymentTermsDTO?.paymentMethodCode !== 'BANK_TRANSFER',
            },
            {
              label: 'createInvoice.account_number',
              value: obj.paymentTermsDTO?.accountNumber,
              hide: obj.paymentTermsDTO?.paymentMethodCode !== 'BANK_TRANSFER',
            },
            {
              label: 'createInvoice.payment_terms',
              value: obj.paymentTermsDTO?.paymentTermsEn,
            },
          ],
          hide: !obj.paymentTermsDTO,
        },
      ],
      image: null,
    },
    {
      key: 'referenceBlock',
      sections: [
        {
          key: 'reference',
          title: 'createInvoice.reference',
          inputs: [
            {
              label: 'createInvoice.reference_to_original_invoice',
              value: obj.originalInvoiceReference,
            },
            {
              label: 'createInvoice.gross_amount_of_referenced_invoice',
              value: '',
            },
            {
              label: 'createInvoice.reason_for_credit_debit_note_issuance',
              value:
                (obj.reasonOfNote &&
                  NOTE_ISSUANCE_REASONS(language)?.find(
                    (reason) => reason.value === obj.reasonOfNote,
                  )?.label) ||
                '',
            },
          ],
        },
      ],
      image: null,
    },
    {
      key: 'notesBlock',
      sections: [
        {
          key: 'notes',
          title: 'createInvoice.notes',
          inputs: [
            {
              label: 'createInvoice.special_tax_treatment',
              value: obj.specialTaxTreatmentEn,
            },
            {
              label: 'createInvoice.notes',
              value: obj.enNotes,
            },
          ],
        },
      ],
      image: null,
    },
  ];

  if (obj.invoiceTypeCode.indexOf('NOTE') === -1) {
    blocks.splice(5, 1);
  }

  return blocks;
};
