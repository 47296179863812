import React from 'react';
import styles from './Filters.styles.scss';
import { Button } from '../../../../components/shared/Button';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { FormikField } from '../../../../components/FormikField';
import {
  InputField,
  DatePickerInputField,
} from '../../../../components/FormikFields';
import parse from 'date-fns/parse';
import { validationSchema } from './Filters.formConfig';
import { convertDataToForm } from './Filters.helpers';

const Filters = ({ onSubmitFilters, currentFilters, onResetFormClick }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={cn('mt-5', styles.container)}>
      <Formik
        validateOnMount
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={convertDataToForm(currentFilters)}
        onSubmit={onSubmitFilters}
      >
        {({ resetForm, values }) => (
          <Form>
            <div className="row">
              <div className="col-lg-3 col-md-5 col-sm-12 d-flex flex-column">
                <FormikField
                  name="search"
                  component={InputField}
                  label={t('common.search')}
                  componentProps={{
                    className: styles.searchInput,
                    placeholder:
                      i18n.language === 'en' ? 'Search...' : '...بحث',
                  }}
                />
              </div>
              <div className="col-lg-3 col-md-5 col-sm-12 d-flex flex-column">
                <p className={styles.label}>
                  {t('solutionList.onboarding_date')}
                </p>
                <div className="d-flex">
                  <div className="d-flex flex-column col-6">
                    <FormikField
                      name="onboardingDateFrom"
                      component={DatePickerInputField}
                      componentProps={{
                        className: cn(styles.datepicker, styles.dateFrom),
                        placeholder: t('common.from'),
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column col-6">
                    <FormikField
                      name="onboardingDateTo"
                      component={DatePickerInputField}
                      componentProps={{
                        disabledDays: values?.onboardingDateFrom
                          ? [
                              {
                                before: parse(
                                  values?.onboardingDateFrom,
                                  'dd/MM/yyyy',
                                  new Date(),
                                ),
                              },
                            ]
                          : [],
                        className: cn(styles.datepicker, styles.dateTo),
                        placeholder: t('common.to'),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-sm-12 d-flex flex-column">
                <p className={styles.label}>
                  {t('solutionList.certificate_expiry_date')}
                </p>
                <div className="d-flex">
                  <div className="d-flex flex-column col-6">
                    <FormikField
                      name="certificateExpiryDateFrom"
                      component={DatePickerInputField}
                      componentProps={{
                        toYear: new Date().getFullYear() + 5,
                        className: cn(styles.datepicker, styles.dateFrom),
                        placeholder: t('common.from'),
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column col-6">
                    <FormikField
                      name="certificateExpiryDateTo"
                      component={DatePickerInputField}
                      componentProps={{
                        toYear: new Date().getFullYear() + 5,
                        disabledDays: values?.certificateExpiryDateFrom
                          ? [
                              {
                                before: parse(
                                  values?.certificateExpiryDateFrom,
                                  'dd/MM/yyyy',
                                  new Date(),
                                ),
                              },
                            ]
                          : [],
                        className: cn(styles.datepicker, styles.dateTo),
                        placeholder: t('common.to'),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-sm-12 d-flex flex-column">
                <p className={styles.label}>
                  {t('solutionList.revocation_date')}
                </p>
                <div className="d-flex">
                  <div className="d-flex flex-column col-6">
                    <FormikField
                      name="revocationDateFrom"
                      component={DatePickerInputField}
                      componentProps={{
                        className: cn(styles.datepicker, styles.dateFrom),
                        placeholder: t('common.from'),
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column col-6">
                    <FormikField
                      name="revocationDateTo"
                      component={DatePickerInputField}
                      componentProps={{
                        disabledDays: values?.revocationDateFrom
                          ? [
                              {
                                before: parse(
                                  values?.revocationDateFrom,
                                  'dd/MM/yyyy',
                                  new Date(),
                                ),
                              },
                            ]
                          : [],
                        className: cn(styles.datepicker, styles.dateTo),
                        placeholder: t('common.to'),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={cn(styles.buttons, 'mt-4')}>
                <Button
                  type="submit"
                  className={cn('col-2 mx-2', styles.filterBtn)}
                  id="manage_filter_btn"
                >
                  {t('common.filter')}
                </Button>
                <Button
                  onClick={() => onResetFormClick(resetForm)}
                  className={cn('col-2 mx-2', styles.resetButton)}
                  variant="outlined"
                  id="manage_reset_btn"
                >
                  {t('common.reset')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(Filters);
